<div id="submitDealCrafter" class="col-md-12" style="text-align: center;padding-bottom: 70px;">
    <div class="container-fluid mobileButtonClass">
        <div class="row">
            <div class="col-md-12">
                <button type="button" [routerLink]="['/display-deal-form']" class="btn cancel-btn"
                    style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
                <button type="submit" class="btn calculate-btn" (click)="submit('calculate')"  >{{'CALCULATE' | translate}}</button>
                <button type="submit" class="btn submit-btn" (click)="submit('save')">{{'SAVE' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="calculateButton">
        <img alt="Calculate" title="Send to SalesForce" (click)="submit('submit')" style="width:60px"
        src="./assets/images/icons/Group 1484.png" />
    </div>

    <app-display-calculation-grid *ngIf="calculated  && (roleId=='3'||roleId=='2')" [tableData]="tableData"></app-display-calculation-grid>
</div>


<app-custom-loader *ngIf="chartLoading"></app-custom-loader>